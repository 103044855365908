import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import React from "react";

export const isJSXElement = (elm: string | JSX.Element | undefined): elm is JSX.Element => {
    return typeof elm !== "string";
};
interface ClientLogos2Props {
    heading?: string | JSX.Element;
}

const ClientLogos2: React.FC<ClientLogos2Props> = ({ heading = "Trusted by <b>2,000+ businesses</b>" }) => {
    const animation = { duration: 30000, easing: (t: any) => t };
    // const count = isMobile ? 2.5 : 6.25;
    const [sliderRef] = useKeenSlider({
        breakpoints: {
            "(max-width: 790px)": {
                slides: { perView: 4.5 },
            },
            "(max-width: 450px)": {
                slides: { perView: 2, origin: "center" },
                loop: true,
                drag: true,
            },
        },
        slides: { perView: 6 },
        loop: true,
        drag: true,
        created(s) {
            s.moveToIdx(5, true, animation);
        },
        updated(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
        },
        animationEnded(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
        },
    });

    const clientLogosData = ["pickyourtrail.svg", "sharan-new.png", "pvr.svg", "american-tourister.png", "Shiprocket.svg", "nas-academy.svg"];
    const clientLogosDataLine2 = ["Vcare.svg", "Kauvery.svg", "Zupay.svg", "grillbox.svg", "rapido.svg", "murugappa.svg", "agni.svg"];

    return (
        <div className="px-8 max-w-6xl mx-auto client-logos flex flex-col gap-3">
            {isJSXElement(heading) ? heading : <h2 className="title" dangerouslySetInnerHTML={{ __html: heading }} />}
            <div ref={sliderRef} className="keen-slider sliderparent h-full relative top-5 ">
                {clientLogosData.map((each, i) => (
                    <div className={`keen-slider__slide slider-card number-slide${i} flex items-center`} key={i}>
                        <div className="w-40 flex" style={{ height: "56px" }}>
                            <Image
                                width={160}
                                height={56}
                                src={`https://assets.gallabox.com/gb-home/common-client-logos-without-border/${each}`}
                                alt={each}
                                className="flex-shrink"
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div ref={sliderRef} className="keen-slider sliderparent h-full relative top-5 ">
                {clientLogosDataLine2.map((each, i) => (
                    <div className={`keen-slider__slide number-slide${i} flex items-center`} key={i}>
                        <div className="w-40 flex" style={{ height: "56px" }}>
                            <Image
                                width={160}
                                height={56}
                                className="pt-2 flex-shrink"
                                src={`https://assets.gallabox.com/gb-home/common-client-logos-without-border/${each}`}
                                alt={each}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ClientLogos2;

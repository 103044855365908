import "keen-slider/keen-slider.min.css";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
// Components
import CaseStudyHomePage from "@components/CaseStudyHomePage";
import ClientLogos2 from "@components/ClientLogos2";
import CtaButton from "@components/CtaButton";
import FeatureCard, { FeatureCardItemProps } from "@components/FeatureCardV2";
import ImageGrid from "@components/ImageGrid";
import IndustryLogos from "@components/IndustryLogos";
import Modal from "@components/Modal/Modal";
import NewLayout from "@components/NewLayout";
import TestimonialNewVersion, { TestimonialDataProps } from "@components/TestimonialNewVersion";

const testimonialData: TestimonialDataProps[] = [
    {
        logoUrl: "https://assets.gallabox.com/gb-home/home-page/testimony/logo/qic-new.png",
        altText: "QIC",
        companyName: "QIC",
        companyTag: "Insurance Company ",
        companyFeedback: (
            <span>
                Gallabox streamlined communication, improved customer service, and increased productivity by 30%. It{" "}
                <em className="text-green-600 font-bold">Integrates with CRM</em>, tracks customer interactions, and is user-friendly. If you&apos;re looking for a way to improve
                your productivity and customer service, I highly recommend Gallabox.
            </span>
        ),
        feedbackImg: "https://assets.gallabox.com/gb-home/home-page/testimony/feedback-imgs/qic.png",
    },
    {
        logoUrl: "https://assets.gallabox.com/gb-home/home-page/testimony/logo/goireland-new.png",
        altText: "GoIreland",
        companyName: "GoIreland",
        companyTag: "Education",
        companyFeedback: (
            <span>
                Earlier a student would spend 15 to 30 minutes connecting with an agent, the widget immediately connects the student and the agent. Implementing the WhatsApp Widget
                led to a massive <em className="text-green-600 font-bold">increase in user engagement </em>
                and, ultimately, conversion rates.
            </span>
        ),
        feedbackImg: "https://assets.gallabox.com/gb-home/home-page/testimony/feedback-imgs/goireland.png",
    },
    {
        logoUrl: "https://assets.gallabox.com/gb-home/home-page/testimony/logo/hugg-new.png",
        altText: "Hugg",
        companyName: "Harsh Doshi",
        companyTag: "D2C",
        companyFeedback: (
            <span>
                We switched to Gallabox and never looked back! It&apos;s the perfect WhatsApp automation solution for businesses of all sizes. With its powerful automation and
                tracking features, we&apos;ve been able to <em className="text-green-600 font-bold">Improve Our Customer Support </em>
                and handle more inquiries than ever before.
            </span>
        ),
        feedbackImg: "https://assets.gallabox.com/gb-home/home-page/testimony/feedback-imgs/hugg.png",
    },
];

export interface RatingImageUrlProps {
    imageUrl: string;
    link: string;
    altText: string;
}
const ratingImagesInput = [
    { imageSource: "https://assets.gallabox.com/gb-home/home-page/rating-new/1.png", link: "https://www.capterra.in/software/1021197/gallabox", altText: "Capterra" },
    // { imageSource: "https://assets.gallabox.com/gb-home/home-page/rating-new/2.png", link: "https://apps.shopify.com/gallabox/reviews", altText: "Shopify" },
    { imageSource: "https://assets.gallabox.com/gb-home/home-page/rating-new/3.png", link: "https://www.g2.com/products/gallabox/reviews", altText: "G2" },
    {
        imageSource: "https://assets.gallabox.com/gb-home/home-page/rating-new/4.png",
        link: "https://marketplace.zoho.com/app/crm/whatsapp-business-messaging-for-zoho-crm",
        altText: "Zoho Marketplace",
    },
    {
        imageSource: "https://assets.gallabox.com/gb-home/home-page/rating-new/5.png",
        link: "https://www.softwareadvice.com/live-chat/gallabox-profile",
        altText: "Software advice",
    },
];

const headerStyle_1 = "lg:text-3xl text-2xl font-bold text-gray-700 lg:text-left text-center";
const headerStyle_2 = "lg:text-3xl text-2xl font-bold text-gray-700";

const featureCardInput: FeatureCardItemProps[] = [
    {
        title: <h3 className={headerStyle_1}>Create Interactive WhatsApp Forms Easily with WhatsApp Flows</h3>,
        description:
            "Build interactive WhatsApp forms easily, share them with your contacts, and put the collected data to work. Focus on other high-impact tasks while WhatsApp Forms handles the data collection process for you!",
        meritsLists: [
            { listItem: "Connect your WhatsApp Flows to chat-bots, templates, and more" },
            { listItem: "Collect data seamlessly from new contacts or existing customers" },
            { listItem: "Collate and analyze data efficiently to derive actionable insights" },
        ],
        buttonText: "Learn more",
        navLink: "/whatsapp-flows",
        mediaType: "image",
        mediaUrl: "https://assets.gallabox.com/gb-home/home-page/whatsapp-forms.png",
        mediaAltText: "WhatsApp Flows",
        mediaPosition: "right",
    },
    {
        title: <h3 className={headerStyle_1}>Enhance Trust and Increase Revenue with AI-Powered Conversations</h3>,
        description:
            "Transcend beyond the realm of standard templates and leave a lasting impression on your customers and prospects with the power of AI-assisted personalization.",
        meritsLists: [
            { listItem: "Get expert assistance from a dependable AI" },
            { listItem: "Respond to customers in their native language" },
            { listItem: "Break down technical jargon into simple messages" },
        ],
        buttonText: "Learn more",
        navLink: "/whatsapp-ai-chatbot",
        mediaType: "video",
        mediaUrl: "https://assets.gallabox.com/gb-home/ai/herosection-mp4.mp4",
        mediaPosition: "left",
    },
    {
        title: (
            <h3 className={headerStyle_1}>
                Boost Your Business with <br /> Click-to-WhatsApp Ads
            </h3>
        ),
        description:
            "Reach out to potential customers where they are most engaged. Utilize Click-to-WhatsApp ads on Facebook and Instagram to encourage viewers to start a dialogue with your business.",
        meritsLists: [{ listItem: "Increase in WhatsApp Conversion Rate" }, { listItem: "Improved customer engagement" }, { listItem: "Personalized retargeting" }],
        buttonText: "Learn more",
        navLink: "/click-to-whatsapp-ads",
        mediaType: "image",
        mediaUrl: "https://assets.gallabox.com/gb-home/home-page/boost-business.png",
        mediaAltText: "Click to WhatsApp Ads",
        mediaPosition: "right",
    },
    {
        title: <h3 className={headerStyle_2}>Shared team inbox makes team collaboration super easy</h3>,
        meritsLists: [
            { listItem: "Real-time context and 360 degree view of all customer conversations" },
            { listItem: "Agents can share live updates and collaborate better with mentions & notes" },
            { listItem: "Super easy to use, similar to texting on WhatsApp" },
        ],
        buttonText: "Learn more",
        navLink: "/whatsapp-shared-inbox",
        mediaType: "image",
        mediaUrl: "https://assets.gallabox.com/gb-home/home-page/shared-inbox.png",
        mediaAltText: "WhatsApp Shared Team Inbox",
        mediaPosition: "left",
    },
    {
        title: (
            <h3 className={headerStyle_2}>
                Personalized broadcast campaigns <br className="lg:block hidden"></br> for a strong sales pipeline
            </h3>
        ),
        meritsLists: [
            { listItem: "Create customer segments based on attributes and past behavior" },
            { listItem: "Add rich media and create interactive messages" },
            { listItem: "Get actionable campaign analytics - sent, delivered, read and replied metrics" },
        ],
        buttonText: "Learn more",
        navLink: "/whatsapp-broadcast",
        mediaType: "image",
        mediaUrl: "https://assets.gallabox.com/gb-home/home-page/broadcast.png",
        mediaAltText: "WhatsApp Broadcast",
        mediaPosition: "right",
    },
    {
        title: <h3 className={headerStyle_2}>Set up product catalogs and start selling on WhatsApp in no time</h3>,
        meritsLists: [
            { listItem: "Showcase your product catalog and send it to customers on WhatsApp" },
            { listItem: "Send order confirmation and billing updates through WhatsApp" },
            { listItem: "Convert WhatsApp into your most powerful selling channel" },
        ],
        buttonText: "Learn more",
        navLink: "/build-your-shop-on-whatsapp",
        mediaType: "image",
        mediaUrl: "https://assets.gallabox.com/gb-home/home-page/product-catalog.png",
        mediaAltText: "WhatsApp Catalog",
        mediaPosition: "left",
    },
    {
        title: <h3 className={headerStyle_2}>Create and send an automated sequence of WhatsApp messages</h3>,
        meritsLists: [
            { listItem: "Sending a series of messages to your prospects over a period of time in a predetermined order." },
            { listItem: "Increase your customer engagement in a jiffy by automating!" },
            { listItem: "Use cases for sequence page - Customer onboarding, Payment dunning, Event Reminders, Product launch updates" },
        ],
        buttonText: "Learn more",
        navLink: "/whatsapp-drip-marketing",
        mediaType: "video",
        mediaUrl: "https://assets.gallabox.com/gb-home/fb-leads/section3.mp4",
        mediaPosition: "right",
    },
];

const wordCarousel = () => {
    const show = document.querySelector("span[data-show]");
    const next = show?.nextElementSibling || document.querySelector(".first-ch");
    const up = document.querySelector("span[data-up]");

    if (up) {
        up.removeAttribute("data-up");
    }

    show?.removeAttribute("data-show");
    show?.setAttribute("data-up", "");

    next?.setAttribute("data-show", "");
};

const Home: React.FC = () => {
    const [modalHeader, setModalHeader] = React.useState<string | null>(null);

    const handleClose = () => {
        setModalHeader(null);
    };

    const [isDollar, setIsDollar] = React.useState<boolean>(false);
    const router = useRouter();
    React.useEffect(() => {
        if (router.query.country) {
            setIsDollar(true);
        }
    }, [router.query.country]);

    useEffect(() => {
        wordCarousel();
        const wordCarouselInterval = setInterval(wordCarousel, 2000);
        return () => clearInterval(wordCarouselInterval);
    }, []);

    const clientLogosData = ["edu2.png", "edu8.png", "fin12.png", "health1.png", "health8.png"];
    const clientLogosDataLine2 = ["real5.png", "travel1.png", "travel12.png", "real1.png", "american-tourister.png"];

    return (
        <NewLayout
            keywords="omnichannel customer support software, whatsapp business api, whatsapp chatbot, whatsapp shared inbox, customer success management, lead management"
            title="Gallabox | WhatsApp Business Automation & WhatsApp chatbots"
            description="Gallabox is a no-code workspace that unlocks the power of WhatsApp to scale your business with Shared Inbox, WhatsApp no-code Chatbot, WhatsApp Broadcasts and more"
            withLiveChat
            canonical="https://gallabox.com"
            showBanner={true}
        >
            <Head>
                <script src="https://fast.wistia.com/embed/medias/730lmkyzty.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
                <link rel="alternate" hrefLang="en" href="https://gallabox.com/" />
                <link rel="alternate" hrefLang="ar" href="https://gallabox.com/ar" />
                <link rel="alternate" hrefLang="x-default" href="https://gallabox.com/" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        legalName: "Gallabox",
                        description:
                            "Gallabox is an Indian SaaS product that helps SMBs automate their customer conversations on WhatsApp. It’s best known for their drag & drop WhatsApp chatbot builder.",
                        url: "https://gallabox.com",
                        logo: "https://gallabox.com/gallabox-logo.svg",
                        email: "team@gallabox.com",
                        parentOrganization: "MangoLeap",
                        sameAs: [
                            "https://www.facebook.com/gallaboxofficial",
                            "https://twitter.com/gallabox",
                            "https://www.instagram.com/gallabox",
                            "https://www.linkedin.com/company/gallabox/",
                            "https://www.youtube.com/c/Gallabox/videos",
                        ],
                        foundingDate: "2021",
                        founders: [
                            {
                                "@type": "Person",
                                name: "Karthik Jagannathan",
                            },
                            {
                                "@type": "Person",
                                name: "Yogesh Narayanan",
                            },
                            {
                                "@type": "Person",
                                name: "Yathindhar Panchanathan",
                            },
                        ],
                        headquarters: {
                            "@type": "Place",
                            address: {
                                "@type": "PostalAddress",
                                addressLocality: "Chennai",
                                addressCountry: {
                                    "@type": "Country",
                                    name: "India",
                                },
                            },
                        },
                        CEO: {
                            "@type": "Person",
                            name: "Karthik Jagannathan",
                        },
                    })}
                </script>
            </Head>

            <section className="app-home lg:mt-32 mt-10 bg-white">
                <header className="relative lg:max-w-6xl lg:mx-auto flex flex-col items-center" style={{ width: "100%", maxWidth: "1600px" }}>
                    <div className="flex lg:flex-row lg:gap-0 flex-col gap-4 justify-center items-center lg:max-w-6xl lg:mx-auto w-full">
                        <h1 className="relative lg:px-4 lg:py-2 p-2 rounded-full lg:text-base text-sm font-semibold text-green-700 bg-green-100 flex flex-row gap-2 items-center flex-nowrap whitespace-nowrap w-fit border border-solid border-green-700">
                            Powered by Official WhatsApp Business API
                            <Image height={24} width={24} src="https://assets.gallabox.com/gb-home/new-zapier/wa-w-whiteborder.svg" alt="whatsapp icon" />
                        </h1>
                        <Image width={210} height={30} className="lg:mx-0 mx-auto" src="/nav-icons/meta-head.svg" alt="meta logo" />
                    </div>
                    <h2 className="head-title mx-auto text-gray-700 text-center md:w-4/5 mt-5 md:mt-3 relative">
                        <span>Turn your WhatsApp</span>
                        <span className="block">
                            <span className="home-head-convo">conversations </span>
                            <span className="block md:inline">
                                <span className="into-txt">into</span>

                                <span className="absolute head-text-top-span">
                                    <span className="mask">
                                        <span style={{ color: "#0083FF" }} className="first-ch">
                                            leads
                                        </span>
                                        <span style={{ color: "#0083FF" }} data-show>
                                            bookings
                                        </span>
                                        <span style={{ color: "#0083FF" }}>deals</span>
                                        <span style={{ color: "#0083FF" }}>orders</span>
                                        <span style={{ color: "#0083FF" }}>fans</span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </h2>
                    <p className="sub-head text-gray-500">Unlock the power of bots with the ease of messaging to grow sales and delight customers.</p>
                    <CtaButton customClass="lg:mt-10 mt-8" type="large" text={"Sign up for free"} />
                </header>

                <div className="my-8 flex justify-center lg:max-w-6xl max-w-sm mx-auto lg:flex-nowrap flex-wrap lg:gap-8 gap-6">
                    {ratingImagesInput.map((input) => {
                        return (
                            <a className="review_rating lg:w-32 lg:h-32 w-20 h-20" key={input.imageSource} href={input.link} target="_blank" rel="noreferrer">
                                <Image src={input.imageSource} alt={input.altText} width={128} height={128} />
                            </a>
                        );
                    })}
                </div>

                <div className="flex justify-center lg:mt-16 mt-8 px-2 lg:px-0 lg:mb-20 mb-20">
                    <iframe
                        src="https://demo.arcade.software/C348ZUOwSw7G35Yyvd2D?embed"
                        frameBorder="0"
                        loading="lazy"
                        allowFullScreen
                        className="hidden lg:block"
                        style={{ colorScheme: "light", width: "1200px", height: "652px" }}
                    ></iframe>

                    <iframe
                        src="https://demo.arcade.software/C348ZUOwSw7G35Yyvd2D?embed"
                        frameBorder="0"
                        loading="lazy"
                        style={{ colorScheme: "light", width: "400px", height: "250px" }}
                        allowFullScreen
                        className="lg:hidden block"
                    ></iframe>
                </div>

                {isDollar ? (
                    <section className="client-logos bg-white mt-24 pb-8 md:pt-0">
                        <IndustryLogos clientLogosData={clientLogosData} clientLogosDataLine2={clientLogosDataLine2} />
                    </section>
                ) : (
                    <ClientLogos2 />
                )}

                <div className="lg:pt-0 lg:pb-20 pt-10 pb-20">
                    <FeatureCard mobileView="image-first" cardData={featureCardInput} />
                </div>

                <div className="bg-green-50 py-20">
                    <section className="lg:max-w-6xl lg:mx-auto w-full flex flex-col gap-8">
                        <h4 className="text-3xl font-bold text-gray-700 text-center">Real People, Real Results</h4>
                        <TestimonialNewVersion testimonialSectionData={testimonialData} />
                    </section>
                </div>

                <section className="w-full h-full" style={{ backgroundImage: "url(/Ellipse.svg)" }}>
                    <ImageGrid />
                </section>

                <section className="bg-white lg:mx-auto lg:max-w-5xl w-full">
                    <CaseStudyHomePage />
                </section>
            </section>
            {modalHeader && <Modal onClose={handleClose} modalHeader={modalHeader} ctaLabel={modalHeader} subjectSuffix={` [${modalHeader}]`} />}
        </NewLayout>
    );
};

export default Home;

import React from "react";
import useSlider from "@hooks/useSlider";
import Image from "next/image";

export interface TestimonialDataProps {
    logoUrl: string;
    altText: string;
    companyName: string;
    companyTag: string;
    companyFeedback: string | JSX.Element;
    feedbackImg: string;
}
export interface TestimonialSectionDataProps {
    testimonialSectionData: TestimonialDataProps[];
}

const TestimonialNewVersion: React.FC<TestimonialSectionDataProps> = (props) => {
    const { testimonialSectionData = [] } = props;
    const { loaded, refCallback, currentSlide, slider } = useSlider({ autoPlay: true, autoPlayInterval: 7000 });

    return (
        <div ref={refCallback} className="keen-slider">
            {testimonialSectionData.map((data, index) => (
                <div key={index} className="keen-slider__slide slider-card flex lg:flex-row flex-col justify-center h-full lg:max-w-6xl lg:mx-auto w-full">
                    <div className="flex flex-col lg:w-1/2 lg:mx-0 w-fit mx-2" style={{ maxHeight: "500px" }}>
                        <div
                            style={{ backgroundColor: "#008069" }}
                            className="flex gap-5 items-center h-fit lg:rounded-tl-xl lg:rounded-tr-none rounded-tl-xl rounded-tr-xl py-6 px-7"
                        >
                            <div className="bg-white rounded-full flex justify-center items-center lg:w-24 lg:h-24 w-20 h-20">
                                {data.logoUrl && <Image priority src={data.logoUrl} alt={data.altText} height={96} width={96} />}
                            </div>
                            <div>
                                {data.companyName && (
                                    <h6 className="lg:text-3xl text-2xl font-bold text-white flex items-center gap-2.5">
                                        {data.companyName}
                                        <span>
                                            <Image
                                                src="https://assets.gallabox.com/gb-home/home-page/testimony/verified-green-tick.png"
                                                alt="Verified green tick"
                                                width={26}
                                                height={26}
                                                loading="lazy"
                                            />
                                        </span>
                                    </h6>
                                )}
                                {data.companyTag && <p className="lg:text-2xl text-base font-normal text-white">{data.companyTag}</p>}
                            </div>
                        </div>
                        <div style={{ background: "linear-gradient(180deg, #EBDFD4 -41.09%, #BEA893 141.09%)" }} className="flex-1 lg:rounded-bl-xl flex flex-col justify-between">
                            <div className="pl-6 pt-5 flex">
                                <div className="w-11/12 flex relative">
                                    {data.companyFeedback && (
                                        <p className="sidevector bg-white lg:text-2xl text-base font-normal text-gray-600 p-3 rounded-b-xl rounded-tr-xl">{data.companyFeedback}</p>
                                    )}
                                </div>
                            </div>
                            <div>
                                {loaded && slider.current && (
                                    <div className="flex lg:justify-start justify-center ml-6">
                                        {[...Array(slider.current.track.details.slides.length).keys()].map((idx) => (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    slider.current?.moveToIdx(idx);
                                                }}
                                                className={
                                                    "ml-1 my-6 rounded-full border border-solid border-white focus:outline-none" +
                                                    (currentSlide === idx ? "ml-8 my-6 w-14 h-2.5 rounded-full bg-white focus:outline-none" : " w-2.5 h-2.5 ")
                                                }
                                                style={{ backgroundColor: currentSlide === idx ? "#FFFFFF" : "#FFFFFF80" }}
                                            ></button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-auto w-fit lg:mx-0 mx-2">
                        {data.feedbackImg && (
                            <Image
                                priority
                                className="lg:rounded-r-xl lg:rounded-bl-none rounded-bl-xl rounded-br-xl"
                                src={data.feedbackImg}
                                alt={data.altText}
                                width={500}
                                height={500}
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TestimonialNewVersion;

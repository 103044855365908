import { useEffect, useRef, useState } from "react";

interface LazyVideoProps {
    src: string;
    type: string;
    poster: string;
}

const LazyVideo: React.FC<LazyVideoProps> = ({ src, type, poster }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const handleIntersection: IntersectionObserverCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsIntersecting(true);
                } else {
                    setIsIntersecting(false);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection);
        const currentVideoRef = videoRef.current;

        if (currentVideoRef) {
            observer.observe(currentVideoRef);
        }

        return () => {
            if (currentVideoRef) {
                observer.unobserve(currentVideoRef);
            }
        };
    }, []);

    useEffect(() => {
        if (isIntersecting && videoRef.current && videoRef.current.paused) {
            videoRef.current.play().catch((error) => {
                console.error("Failed to play video:", error);
            });
        }
    }, [isIntersecting]);

    return (
        <video ref={videoRef} controls={false} muted loop playsInline poster={poster}>
            <source src={src} type={type} />
        </video>
    );
};

export default LazyVideo;

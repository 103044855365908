import { CaseStudyHomePageInput } from "@pages/case-study";
import React from "react";
import CaseStudyBanner from "./CaseStudyComponents/CaseStudyBanner";

const CaseStudyHomePage: React.FC = () => {
    return (
        <div className="flex flex-col items-center my-20 w-full">
            <h3 className="p-0 m-0 text-2xl lg:text-3xl text-gray-700 font-bold text-center max-w-screen-md leading-snug tracking-wide mb-8" style={{ fontFamily: "Gotham" }}>
                See How We Helped Businesses Like Yours
            </h3>

            <div className="flex lg:flex-row flex-col gap-6 w-full">
                {CaseStudyHomePageInput.map((item, index) => (
                    <div key={index} className="lg:w-1/3 md:w-1/2 w-fit lg:mx-0 md:mx-auto mx-5">
                        <CaseStudyBanner id={item.id} bannerimg={item.bannerimg} tag={item.tag} title={item.title} link={item.link} containerStyle="min-w-full" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CaseStudyHomePage;

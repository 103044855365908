import Image from "next/image";
import React, { FC } from "react";
import { AppButton } from "./Typography";

interface ImageGridProps {
    title?: string;
    description?: string;
    content?: string;
    showButton?: boolean;
    buttonTxt?: string;
    href?: string;
}

const ImageGrid: FC<ImageGridProps> = (props) => {
    const {
        title = "Integrations",
        description = "Connect Your Tech Stack with Gallabox Easily",
        content = "Got a diverse software stack? No problem! Gallabox will fit perfectly in by seamlessly integrating with your existing software ranging from CRM to payment processors.",
        showButton = true,
        buttonTxt = "View Integrations",
        href = "/whatsapp-integrations",
    } = props;
    const testArray = [
        "hubspot.png",
        "zoho2.png",
        "leadsquare.png",
        "facebook.png",
        "woocommerce.png",
        "shopify.png",
        "kylas.png",
        "shiprocket.png",
        "googlesheets.png",
        "cashfree.png",
        "zapier.png",
        "payu.png",
        "razorpay.png",
        "webengage.png",
    ];

    for (let i = 0; i < 14; i++) {
        testArray.push("pickyourtrail.svg");
    }

    return (
        <section className="w-full h-full py-10  " style={{ backgroundImage: "url(/Ellipse.svg)" }}>
            <div className="  pb-8 lg:px-0 px-3 max-w-6xl mx-auto">
                <div className="header text-center mt-8">
                    <div className="text-blue-600 pb-4 font-bold">{title}</div>
                    <h3 className="lg:text-4xl text-2xl font-bold pb-4">{description}</h3>
                    <div className="lg:text-lg text-base mb-10 lg:px-52 px">{content}</div>
                    <div className="hidden lg:block">
                        <div className="flex justify-center">
                            <div className="flex flex-col">
                                {[testArray.slice(0, 6), testArray.slice(6, 11), testArray.slice(11, 14)].map((row, rowIndex) => (
                                    <div key={rowIndex} className="flex  flex-row items-center justify-center space-x-4">
                                        {row.map((img) => (
                                            <div key={img} className="flex items-center pb-2">
                                                <Image
                                                    width={175}
                                                    height={75}
                                                    src={`https://assets.gallabox.com/gb-home/integration-component-logos/${img}`}
                                                    alt="integration-logo-list-1"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap mt-8 lg:hidden">
                    <div className="w-full md:w-1/2">
                        <div className="flex flex-wrap justify-center">
                            {testArray.slice(0, 8).map((img) => (
                                <div key={img} className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 p-2">
                                    <div className="flex items-center">
                                        <Image
                                            width={175}
                                            height={75}
                                            src={`https://assets.gallabox.com/gb-home/integration-component-logos/${img}`}
                                            alt="integration-logo-list2"
                                            className="w-full"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full md:w-1/2">
                        <div className="flex flex-wrap justify-center">
                            {testArray.slice(8, 14).map((img) => (
                                <div key={img} className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 p-2">
                                    <div className="flex items-center">
                                        <Image
                                            width={175}
                                            height={75}
                                            src={`https://assets.gallabox.com/gb-home/integration-component-logos/${img}`}
                                            alt="integration-logo-list3"
                                            className="w-full"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {showButton && (
                    <div className="w-full justify-center mt-12 lg:flex">
                        <div className="flex flex-col justify-center">
                            <AppButton buttonText={buttonTxt} href={href} />
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default ImageGrid;

import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import React from "react";

interface IndustryLogosProps {
    heading?: string;
    clientLogosData: string[];
    clientLogosDataLine2: string[];
}

interface FeaturesMarqueeProps {
    features: string[];
    direction: string;
}

const FeaturesMarquee: React.FC<FeaturesMarqueeProps> = ({ features }) => {
    const animation = { duration: 30000, easing: (t: any) => t };
    const [sliderRef] = useKeenSlider({
        breakpoints: {
            "(max-width: 790px)": {
                slides: { perView: 4.5 },
            },
            "(max-width: 450px)": {
                slides: { perView: 2, origin: "center" },
                loop: true,
                drag: true,
            },
        },
        slides: { perView: 3 },
        loop: true,
        drag: true,
        created(s) {
            s.moveToIdx(5, true, animation);
        },
        updated(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
        },
        animationEnded(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
        },
    });

    return (
        <div ref={sliderRef} className="keen-slider">
            {features.map((data, ind) => (
                <div key={ind} className={`keen-slider__slide number-slide${ind} flex justify-center items-center`}>
                    <Image height={55} width={125} src={`https://assets.gallabox.com/gb-home/industry-logos/new/${data}`} alt="mobile-integration-logo-list" className="logo" />
                </div>
            ))}
        </div>
    );
};

const IndustryLogos: React.FC<IndustryLogosProps> = ({ clientLogosData, clientLogosDataLine2 }) => {
    //const animation = { duration: 30000, easing: (t: any) => t };
    // const count = isMobile ? 2.5 : 6.25;

    return (
        <>
            <div className="  client-logos py-6 max-w-6xl mx-auto ">
                <h2 className="title">
                    Trusted by <b>2,000+ businesses</b>
                </h2>

                <div className="logos-wrapper logo-desktop w-fit mx-auto">
                    {clientLogosData.map((data) => (
                        <div key={data} className="flex items-center justify-center h-12 w-40">
                            <img src={`https://assets.gallabox.com/gb-home/industry-logos/new/${data}`} alt="client-logo" className="w-full" />
                        </div>
                    ))}
                </div>
                <div
                    className="logos-wrapper logo-desktop second-row-logos mt-5 mx-auto w-fit pt-5"
                    style={{
                        marginTop: 0,
                    }}
                >
                    {clientLogosDataLine2.map((data) => (
                        <div key={data} className="flex items-center justify-center h-12 w-40">
                            <img src={`https://assets.gallabox.com/gb-home/industry-logos/new/${data}`} alt="client-logo" className="w-full" />
                        </div>
                    ))}
                </div>

                <div className="logo-mobile">
                    <FeaturesMarquee features={clientLogosData} direction="ltr" />
                    <FeaturesMarquee features={clientLogosDataLine2} direction="rtl" />
                </div>
            </div>
        </>
    );
};

export default IndustryLogos;

import LazyVideo from "@components/LandingPageComponents/LazyVideoComponent";
import Image from "next/image";
import React from "react";

export interface FeatureCardMediaProps {
    mediaType: "image" | "video" | "imageCarousel";
    mediaUrl: string;
    mediaAltText?: string;
}

const FeatureCardMedia: React.FC<FeatureCardMediaProps> = (props) => {
    const { mediaType, mediaUrl, mediaAltText } = props;
    return (
        <div className="lg:w-1/2 lg:mx-0 w-fit mx-4 flex items-center justify-center">
            {mediaType === "image" && <Image src={mediaUrl} alt={mediaAltText ?? ""} width={585} height={500} />}
            {mediaType === "video" && <LazyVideo src={mediaUrl} type="video/mp4" poster="" />}
        </div>
    );
};

export default FeatureCardMedia;
